import { CityTZProps, TimeZoneRowData } from "../timezone-table/interface";

export const Shanghai: CityTZProps = {
    cityName: 'Shanghai',
    countryCode: 'CN',
    country: 'China',
    State: 'Shanghai',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 31.2304,
    Long: 121.4737,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Chicago: CityTZProps = {
    cityName: 'Chicago',
    countryCode: 'US',
    country: 'United States',
    State: 'Illinois',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 41.8781,
    Long: -87.6298,
    tzID: 'America/Chicago',
    UTCOffset: {
        STD: {
            hour: -6,
            minute: 0
        },
        DST: {
            hour: -5,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CDT'
    }
};

export const London: CityTZProps = {
    cityName: 'London',
    countryCode: 'GB',
    country: 'United Kingdom',
    State: 'England',
    Languages: ['English'],
    Currency: 'GBP',
    Lat: 51.5074,
    Long: -0.1278,
    tzID: 'Europe/London',
    UTCOffset: {
        STD: {
            hour: 0,
            minute: 0
        },
        DST: {
            hour: 1,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'GMT',
        DST: 'BST'
    }
};

export const Beijing: CityTZProps = {
    cityName: 'Beijing',
    countryCode: 'CN',
    country: 'China',
    State: 'Beijing',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 39.9042,
    Long: 116.4074,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const NewYork: CityTZProps = {
    cityName: 'New York',
    countryCode: 'US',
    country: 'United States',
    State: 'New York',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 40.7128,
    Long: -74.0060,
    tzID: 'America/New_York',
    UTCOffset: {
        STD: {
            hour: -5,
            minute: 0
        },
        DST: {
            hour: -4,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EST',
        DST: 'EDT'
    }
};  

export const Tokyo: CityTZProps = {
    cityName: 'Tokyo',
    countryCode: 'JP',
    country: 'Japan',
    State: 'Tokyo',
    Languages: ['Japanese'],
    Currency: 'Yen',
    Lat: 35.6895,
    Long: 139.6917,
    tzID: 'Asia/Tokyo',
    UTCOffset: {
        STD: {
            hour: 9,
            minute: 0
        },
        DST: {
            hour: 9,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'JST',
        DST: 'JST'
    }
};

export const Paris: CityTZProps = {
    cityName: 'Paris',
    countryCode: 'FR',
    country: 'France',
    State: 'Paris',
    Languages: ['French'],
    Currency: 'Euro',
    Lat: 48.8566,
    Long: 2.3522,
    tzID: 'Europe/Paris',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Sydney: CityTZProps = {
    cityName: 'Sydney',
    countryCode: 'AU',
    country: 'Australia',
    State: 'New South Wales',
    Languages: ['English'],
    Currency: 'AUD',
    Lat: -33.8688,
    Long: 151.2093,
    tzID: 'Australia/Sydney',
    UTCOffset: {
        STD: {
            hour: 10,
            minute: 0
        },
        DST: {
            hour: 10,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'AEST',
        DST: 'AEDT'
    }
};

export const LosAngeles: CityTZProps = {
    cityName: 'Los Angeles',
    countryCode: 'US',
    country: 'United States',
    State: 'California',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 34.0522,
    Long: -118.2437,
    tzID: 'America/Los_Angeles',
    UTCOffset: {
        STD: {
            hour: -8,
            minute: 0
        },
        DST: {
            hour: -7,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'PST',
        DST: 'PDT'
    }
};

export const Berlin: CityTZProps = {
    cityName: 'Berlin',
    countryCode: 'DE',
    country: 'Germany',
    State: 'Berlin',
    Languages: ['German'],
    Currency: 'Euro',
    Lat: 52.5200,
    Long: 13.4050,
    tzID: 'Europe/Berlin',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Moscow: CityTZProps = {
    cityName: 'Moscow',
    countryCode: 'RU',
    country: 'Russia',
    State: 'Moscow',
    Languages: ['Russian'],
    Currency: 'Ruble',
    Lat: 55.7558,
    Long: 37.6176,
    tzID: 'Europe/Moscow',
    UTCOffset: {
        STD: {
            hour: 3,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'MSK',
        DST: 'MSK'
    }
};

export const SaoPaulo: CityTZProps = {
    cityName: 'Sao Paulo',
    countryCode: 'BR',
    country: 'Brazil',
    State: 'Sao Paulo',
    Languages: ['Portuguese'],
    Currency: 'Real',
    Lat: -23.5505,
    Long: -46.6333,
    tzID: 'America/Sao_Paulo',
    UTCOffset: {
        STD: {
            hour: -3,
            minute: 0
        },
        DST: {
            hour: -2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'BRT',
        DST: 'BRST'
    }
};

export const Dubai: CityTZProps = {
    cityName: 'Dubai',
    countryCode: 'AE',
    country: 'United Arab Emirates',
    State: 'Dubai',
    Languages: ['Arabic'],
    Currency: 'Dirham',
    Lat: 25.276987,
    Long: 55.296249,
    tzID: 'Asia/Dubai',
    UTCOffset: {
        STD: {
            hour: 4,
            minute: 0
        },
        DST: {
            hour: 4,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'GST',
        DST: 'GST'
    }
};

export const Mumbai: CityTZProps = {
    cityName: 'Mumbai',
    countryCode: 'IN',
    country: 'India',
    State: 'Maharashtra',
    Languages: ['Hindi', 'Marathi'],
    Currency: 'Rupee',
    Lat: 19.0760,
    Long: 72.8777,
    tzID: 'Asia/Kolkata',
    UTCOffset: {
        STD: {
            hour: 5,
            minute: 30
        },
        DST: {
            hour: 5,
            minute: 30
        }
    },
    tzAbbr: {
        STD: 'IST',
        DST: 'IST'
    }
};

export const Toronto: CityTZProps = {
    cityName: 'Toronto',
    countryCode: 'CA',
    country: 'Canada',
    State: 'Ontario',
    Languages: ['English', 'French'],
    Currency: 'CAD',
    Lat: 43.65107,
    Long: -79.347015,
    tzID: 'America/Toronto',
    UTCOffset: {
        STD: {
            hour: -5,
            minute: 0
        },
        DST: {
            hour: -4,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EST',
        DST: 'EDT'
    }
};

export const Istanbul: CityTZProps = {
    cityName: 'Istanbul',
    countryCode: 'TR',
    country: 'Turkey',
    State: 'Istanbul',
    Languages: ['Turkish'],
    Currency: 'Lira',
    Lat: 41.0082,
    Long: 28.9784,
    tzID: 'Europe/Istanbul',
    UTCOffset: {
        STD: {
            hour: 3,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EET',
        DST: 'EET'
    }
};

export const Seoul: CityTZProps = {
    cityName: 'Seoul',
    countryCode: 'KR',
    country: 'South Korea',
    State: 'Seoul',
    Languages: ['Korean'],
    Currency: 'Won',
    Lat: 37.5665,
    Long: 126.9780,
    tzID: 'Asia/Seoul',
    UTCOffset: {
        STD: {
            hour: 9,
            minute: 0
        },
        DST: {
            hour: 9,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'KST',
        DST: 'KST'
    }
};

export const Jakarta: CityTZProps = {
    cityName: 'Jakarta',
    countryCode: 'ID',
    country: 'Indonesia',
    State: 'Jakarta',
    Languages: ['Indonesian'],
    Currency: 'Rupiah',
    Lat: -6.2088,
    Long: 106.8456,
    tzID: 'Asia/Jakarta',
    UTCOffset: {
        STD: {
            hour: 7,
            minute: 0
        },
        DST: {
            hour: 7,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'WIB',
        DST: 'WIB'
    }
};

export const Cairo: CityTZProps = {
    cityName: 'Cairo',
    countryCode: 'EG',
    country: 'Egypt',
    State: 'Cairo',
    Languages: ['Arabic'],
    Currency: 'Pound',
    Lat: 30.0444,
    Long: 31.2357,
    tzID: 'Africa/Cairo',
    UTCOffset: {
        STD: {
            hour: 2,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EET',
        DST: 'EET'
    }
};

export const MexicoCity: CityTZProps = {
    cityName: 'Mexico City',
    countryCode: 'MX',
    country: 'Mexico',
    State: 'Mexico City',
    Languages: ['Spanish'],
    Currency: 'Peso',
    Lat: 19.4326,
    Long: -99.1332,
    tzID: 'America/Mexico_City',
    UTCOffset: {
        STD: {
            hour: -6,
            minute: 0
        },
        DST: {
            hour: -5,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CDT'
    }
};

export const Bangkok: CityTZProps = {
    cityName: 'Bangkok',
    countryCode: 'TH',
    country: 'Thailand',
    State: 'Bangkok',
    Languages: ['Thai'],
    Currency: 'Baht',
    Lat: 13.7563,
    Long: 100.5018,
    tzID: 'Asia/Bangkok',
    UTCOffset: {
        STD: {
            hour: 7,
            minute: 0
        },
        DST: {
            hour: 7,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'ICT',
        DST: 'ICT'
    }
};

export const BuenosAires: CityTZProps = {
    cityName: 'Buenos Aires',
    countryCode: 'AR',
    country: 'Argentina',
    State: 'Buenos Aires',
    Languages: ['Spanish'],
    Currency: 'Peso',
    Lat: -34.6037,
    Long: -58.3816,
    tzID: 'America/Argentina/Buenos_Aires',
    UTCOffset: {
        STD: {
            hour: -3,
            minute: 0
        },
        DST: {
            hour: -3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'ART',
        DST: 'ART'
    }
};

export const Johannesburg: CityTZProps = {
    cityName: 'Johannesburg',
    countryCode: 'ZA',
    country: 'South Africa',
    State: 'Gauteng',
    Languages: ['English', 'Afrikaans'],
    Currency: 'Rand',
    Lat: -26.2041,
    Long: 28.0473,
    tzID: 'Africa/Johannesburg',
    UTCOffset: {
        STD: {
            hour: 2,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'SAST',
        DST: 'SAST'
    }
};

export const Lagos: CityTZProps = {
    cityName: 'Lagos',
    countryCode: 'NG',
    country: 'Nigeria',
    State: 'Lagos',
    Languages: ['English'],
    Currency: 'Naira',
    Lat: 6.5244,
    Long: 3.3792,
    tzID: 'Africa/Lagos',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 1,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'WAT',
        DST: 'WAT'
    }
};

export const Chengdu: CityTZProps = {
    cityName: 'Chengdu',
    countryCode: 'CN',
    country: 'China',
    State: 'Sichuan',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 30.5728,
    Long: 104.0668,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Hangzhou: CityTZProps = {
    cityName: 'Hangzhou',
    countryCode: 'CN',
    country: 'China',
    State: 'Zhejiang',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 30.2741,
    Long: 120.1551,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const XiAn: CityTZProps = {
    cityName: 'Xi An',
    countryCode: 'CN',
    country: 'China',
    State: 'Shaanxi',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 34.3416,
    Long: 108.9398,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Wuhan: CityTZProps = {
    cityName: 'Wuhan',
    countryCode: 'CN',
    country: 'China',
    State: 'Hubei',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 30.5928,
    Long: 114.3055,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Shenzhen: CityTZProps = {
    cityName: 'Shenzhen',
    countryCode: 'CN',
    country: 'China',
    State: 'Guangdong',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 22.5431,
    Long: 114.0579,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Nanjing: CityTZProps = {
    cityName: 'Nanjing',
    countryCode: 'CN',
    country: 'China',
    State: 'Jiangsu',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 32.0603,
    Long: 118.7969,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Qingdao: CityTZProps = {
    cityName: 'Qingdao',
    countryCode: 'CN',
    country: 'China',
    State: 'Shandong',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 36.0671,
    Long: 120.3826,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Dalian: CityTZProps = {
    cityName: 'Dalian',
    countryCode: 'CN',
    country: 'China',
    State: 'Liaoning',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 38.9140,
    Long: 121.6147,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Tianjin: CityTZProps = {
    cityName: 'Tianjin',
    countryCode: 'CN',
    country: 'China',
    State: 'Tianjin',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 39.0837,
    Long: 117.2264,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Guangzhou: CityTZProps = {
    cityName: 'Guangzhou',
    countryCode: 'CN',
    country: 'China',
    State: 'Guangdong',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 23.1291,
    Long: 113.2644,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Shijiazhuang: CityTZProps = {
    cityName: 'Shijiazhuang',
    countryCode: 'CN',
    country: 'China',
    State: 'Hebei',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 38.0428,
    Long: 114.5149,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};


export const Chongqing: CityTZProps = {
    cityName: 'Chongqing',
    countryCode: 'CN',
    country: 'China',
    State: 'Chongqing',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 29.4316,
    Long: 106.9123,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Kabul: CityTZProps = {
    cityName: 'Kabul',
    countryCode: 'AF',
    country: 'Afghanistan',
    State: 'Kabul',
    Languages: ['Pashto', 'Dari'],
    Currency: 'Afghani',
    Lat: 34.5553,
    Long: 69.2075,
    tzID: 'Asia/Kabul',
    UTCOffset: {
        STD: {
            hour: 4,
            minute: 30
        },
        DST: {
            hour: 4,
            minute: 30
        }
    },
    tzAbbr: {
        STD: 'AFT',
        DST: 'AFT'
    }
};

export const Baghdad: CityTZProps = {
    cityName: 'Baghdad',
    countryCode: 'IQ',
    country: 'Iraq',
    State: 'Baghdad',
    Languages: ['Arabic', 'Kurdish'],
    Currency: 'Dinar',
    Lat: 33.3152,
    Long: 44.3661,
    tzID: 'Asia/Baghdad',
    UTCOffset: {
        STD: {
            hour: 3,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'AST',
        DST: 'AST'
    }
};

export const Tehran: CityTZProps = {
    cityName: 'Tehran',
    countryCode: 'IR',
    country: 'Iran',
    State: 'Tehran',
    Languages: ['Persian'],
    Currency: 'Rial',
    Lat: 35.6892,
    Long: 51.3890,
    tzID: 'Asia/Tehran',
    UTCOffset: {
        STD: {
            hour: 3,
            minute: 30
        },
        DST: {
            hour: 4,
            minute: 30
        }
    },
    tzAbbr: {
        STD: 'IRST',
        DST: 'IRDT'
    }
};

export const Riyadh: CityTZProps = {
    cityName: 'Riyadh',
    countryCode: 'SA',
    country: 'Saudi Arabia',
    State: 'Riyadh',
    Languages: ['Arabic'],
    Currency: 'Riyal',
    Lat: 24.7136,
    Long: 46.6753,
    tzID: 'Asia/Riyadh',
    UTCOffset: {
        STD: {
            hour: 3,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'AST',
        DST: 'AST'
    }
};

export const Taipei: CityTZProps = {
    cityName: 'Taipei',
    countryCode: 'TW',
    country: 'Taiwan',
    State: 'Taipei',
    Languages: ['Chinese'],
    Currency: 'Dollar',
    Lat: 25.0329,
    Long: 121.5654,
    tzID: 'Asia/Taipei',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

export const Minsk: CityTZProps = {
    cityName: 'Minsk',
    countryCode: 'BY',
    country: 'Belarus',
    State: 'Minsk',
    Languages: ['Belarusian', 'Russian'],
    Currency: 'Ruble',
    Lat: 53.9045,
    Long: 27.5615,
    tzID: 'Europe/Minsk',
    UTCOffset: {
        STD: {
            hour: 3,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'FET',
        DST: 'FET'
    }
};

export const Roma : CityTZProps = {
    cityName: 'Roma',
    countryCode: 'IT',
    country: 'Italy',
    State: 'Roma',
    Languages: ['Italian'],
    Currency: 'Euro',
    Lat: 41.9028,
    Long: 12.4964,
    tzID: 'Europe/Rome',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Malta : CityTZProps = {
    cityName: 'Valletta',
    countryCode: 'MT',
    country: 'Malta',
    State: 'Valletta',
    Languages: ['Maltese', 'English'],
    Currency: 'Euro',
    Lat: 35.8989,
    Long: 14.5146,
    tzID: 'Europe/Malta',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Athens : CityTZProps = {
    cityName: 'Athens',
    countryCode: 'GR',
    country: 'Greece',
    State: 'Athens',
    Languages: ['Greek'],
    Currency: 'Euro',
    Lat: 37.9838,
    Long: 23.7275,
    tzID: 'Europe/Athens',
    UTCOffset: {
        STD: {
            hour: 2,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EET',
        DST: 'EEST'
    }
};

export const Prague: CityTZProps = {
    cityName: 'Prague',
    countryCode: 'CZ',
    country: 'Czech Republic',
    State: 'Prague',
    Languages: ['Czech'],
    Currency: 'Koruna',
    Lat: 50.0755,
    Long: 14.4378,
    tzID: 'Europe/Prague',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Warsaw: CityTZProps = {
    cityName: 'Warsaw',
    countryCode: 'PL',
    country: 'Poland',
    State: 'Warsaw',
    Languages: ['Polish'],
    Currency: 'Zloty',
    Lat: 52.2297,
    Long: 21.0122,
    tzID: 'Europe/Warsaw',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Vienna: CityTZProps = {
    cityName: 'Vienna',
    countryCode: 'AT',
    country: 'Austria',
    State: 'Vienna',
    Languages: ['German'],
    Currency: 'Euro',
    Lat: 48.2082,
    Long: 16.3738,
    tzID: 'Europe/Vienna',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Madrid: CityTZProps = {
    cityName: 'Madrid',
    countryCode: 'ES',
    country: 'Spain',
    State: 'Madrid',
    Languages: ['Spanish'],
    Currency: 'Euro',
    Lat: 40.4168,
    Long: -3.7038,
    tzID: 'Europe/Madrid',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Stockholm: CityTZProps = {
    cityName: 'Stockholm',
    countryCode: 'SE',
    country: 'Sweden',
    State: 'Stockholm',
    Languages: ['Swedish'],
    Currency: 'Krona',
    Lat: 59.3293,
    Long: 18.0686,
    tzID: 'Europe/Stockholm',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Oslo: CityTZProps = {
    cityName: 'Oslo',
    countryCode: 'NO',
    country: 'Norway',
    State: 'Oslo',
    Languages: ['Norwegian'],
    Currency: 'Krone',
    Lat: 59.9139,
    Long: 10.7522,
    tzID: 'Europe/Oslo',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Helsinki: CityTZProps = {
    cityName: 'Helsinki',
    countryCode: 'FI',
    country: 'Finland',
    State: 'Helsinki',
    Languages: ['Finnish'],
    Currency: 'Euro',
    Lat: 60.1695,
    Long: 24.9354,
    tzID: 'Europe/Helsinki',
    UTCOffset: {
        STD: {
            hour: 2,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EET',
        DST: 'EEST'
    }
};

export const Copenhagen: CityTZProps = {
    cityName: 'Copenhagen',
    countryCode: 'DK',
    country: 'Denmark',
    State: 'Copenhagen',
    Languages: ['Danish'],
    Currency: 'Krone',
    Lat: 55.6761,
    Long: 12.5683,
    tzID: 'Europe/Copenhagen',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Amsterdam: CityTZProps = {
    cityName: 'Amsterdam',
    countryCode: 'NL',
    country: 'Netherlands',
    State: 'Amsterdam',
    Languages: ['Dutch'],
    Currency: 'Euro',
    Lat: 52.3676,
    Long: 4.9041,
    tzID: 'Europe/Amsterdam',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Brussels: CityTZProps = {
    cityName: 'Brussels',
    countryCode: 'BE',
    country: 'Belgium',
    State: 'Brussels',
    Languages: ['Dutch', 'French', 'German'],
    Currency: 'Euro',
    Lat: 50.8503,
    Long: 4.3517,
    tzID: 'Europe/Brussels',
    UTCOffset: {
        STD: {
            hour: 1,
            minute: 0
        },
        DST: {
            hour: 2,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CET',
        DST: 'CEST'
    }
};

export const Hongkong: CityTZProps = {
    cityName: 'Hong Kong',
    countryCode: 'HK',
    country: 'Hong Kong',
    State: 'Hong Kong',
    Languages: ['Chinese'],
    Currency: 'Dollar',
    Lat: 22.3193,
    Long: 114.1694,
    tzID: 'Asia/Hong_Kong',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'HKT',
        DST: 'HKT'
    }
};

export const Singapore: CityTZProps = {
    cityName: 'Singapore',
    countryCode: 'SG',
    country: 'Singapore',
    State: 'Singapore',
    Languages: ['Chinese', 'Malay', 'Tamil'],
    Currency: 'Dollar',
    Lat: 1.3521,
    Long: 103.8198,
    tzID: 'Asia/Singapore',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'SGT',
        DST: 'SGT'
    }
};

export const KualaLumpur: CityTZProps = {
    cityName: 'Kuala Lumpur',
    countryCode: 'MY',
    country: 'Malaysia',
    State: 'Kuala Lumpur',
    Languages: ['Malay'],
    Currency: 'Ringgit',
    Lat: 3.1390,
    Long: 101.6869,
    tzID: 'Asia/Kuala_Lumpur',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'MYT',
        DST: 'MYT'
    }
};

export const Manila: CityTZProps = {
    cityName: 'Manila',
    countryCode: 'PH',
    country: 'Philippines',
    State: 'Manila',
    Languages: ['Filipino', 'English'],
    Currency: 'Peso',
    Lat: 14.5995,
    Long: 120.9842,
    tzID: 'Asia/Manila',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'PHT',
        DST: 'PHT'
    }
};

export const Kiev: CityTZProps = {
    cityName: 'Kiev',
    countryCode: 'UA',
    country: 'Ukraine',
    State: 'Kiev',
    Languages: ['Ukrainian'],
    Currency: 'Hryvnia',
    Lat: 50.4501,
    Long: 30.5234,
    tzID: 'Europe/Kiev',
    UTCOffset: {
        STD: {
            hour: 2,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EET',
        DST: 'EEST'
    }
};

export const Phoenix: CityTZProps = {
    cityName: 'Phoenix',
    countryCode: 'US',
    country: 'United States',
    State: 'Arizona',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 33.4484,
    Long: -112.0740,
    tzID: 'America/Phoenix',
    UTCOffset: {
        STD: {
            hour: -7,
            minute: 0
        },
        DST: {
            hour: -7,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'MST',
        DST: 'MST'
    }
};

export const Nome: CityTZProps = {
    cityName: 'Nome',
    countryCode: 'US',
    country: 'United States',
    State: 'Alaska',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 64.5011,
    Long: -165.4064,
    tzID: 'America/Nome',
    UTCOffset: {
        STD: {
            hour: -9,
            minute: 0
        },
        DST: {
            hour: -8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'AKST',
        DST: 'AKDT'
    }
};

export const Seattle: CityTZProps = {
    cityName: 'Seattle',
    countryCode: 'US',
    country: 'United States',
    State: 'Washington',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 47.6062,
    Long: -122.3321,
    tzID: 'America/Los_Angeles',
    UTCOffset: {
        STD: {
            hour: -8,
            minute: 0
        },
        DST: {
            hour: -7,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'PST',
        DST: 'PDT'
    }
};

export const Tallinn: CityTZProps = {
    cityName: 'Tallinn',
    countryCode: 'EE',
    country: 'Estonia',
    State: 'Tallinn',
    Languages: ['Estonian'],
    Currency: 'Euro',
    Lat: 59.4370,
    Long: 24.7536,
    tzID: 'Europe/Tallinn',
    UTCOffset: {
        STD: {
            hour: 2,
            minute: 0
        },
        DST: {
            hour: 3,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EET',
        DST: 'EEST'
    }
};

export const Anchorage: CityTZProps = {
    cityName: 'Anchorage',
    countryCode: 'US',
    country: 'United States',
    State: 'Alaska',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 61.2181,
    Long: -149.9003,
    tzID: 'America/Anchorage',
    UTCOffset: {
        STD: {
            hour: -9,
            minute: 0
        },
        DST: {
            hour: -8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'AKST',
        DST: 'AKDT'
    }
};

export const Denver: CityTZProps = {
    cityName: 'Denver',
    countryCode: 'US',
    country: 'United States',
    State: 'Colorado',
    Languages: ['English'],
    Currency: 'USD',
    Lat: 39.7392,
    Long: -104.9903,
    tzID: 'America/Denver',
    UTCOffset: {
        STD: {
            hour: -7,
            minute: 0
        },
        DST: {
            hour: -6,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'MST',
        DST: 'MDT'
    }
};

export const PanamaCity: CityTZProps = {
    cityName: 'Panama City',
    countryCode: 'PA',
    country: 'Panama',
    State: 'Panama City',
    Languages: ['Spanish'],
    Currency: 'Balboa',
    Lat: 8.9824,
    Long: -79.5199,
    tzID: 'America/Panama',
    UTCOffset: {
        STD: {
            hour: -5,
            minute: 0
        },
        DST: {
            hour: -5,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'EST',
        DST: 'EST'
    }
};

export const Dhaka: CityTZProps = {
    cityName: 'Dhaka',
    countryCode: 'BD',
    country: 'Bangladesh',
    State: 'Dhaka',
    Languages: ['Bengali'],
    Currency: 'Taka',
    Lat: 23.8103,
    Long: 90.4125,
    tzID: 'Asia/Dhaka',
    UTCOffset: {
        STD: {
            hour: 6,
            minute: 0
        },
        DST: {
            hour: 6,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'BDT',
        DST: 'BDT'
    }
};
export const Urumqi: CityTZProps = {
    cityName: 'Urumqi',
    countryCode: 'CN',
    country: 'China',
    State: 'Xinjiang',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 43.8256,
    Long: 87.6168,
    tzID: 'Asia/Urumqi',
    UTCOffset: {
        STD: {
            hour: 6,
            minute: 0
        },
        DST: {
            hour: 6,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'XJT',
        DST: 'XJT'
    }
};

export const Suzhou: CityTZProps = {
    cityName: 'Suzhou',
    countryCode: 'CN',
    country: 'China',
    State: 'Jiangsu',
    Languages: ['Chinese'],
    Currency: 'Yuan',
    Lat: 31.2989,
    Long: 120.5853,
    tzID: 'Asia/Shanghai',
    UTCOffset: {
        STD: {
            hour: 8,
            minute: 0
        },
        DST: {
            hour: 8,
            minute: 0
        }
    },
    tzAbbr: {
        STD: 'CST',
        DST: 'CST'
    }
};

const CityArray = [
    Seoul,
    Jakarta,
    Cairo,
    MexicoCity,
    Bangkok,
    BuenosAires,
    Johannesburg,
    Lagos,
    Chengdu,
    Hangzhou,
    XiAn,
    Wuhan,
    Shenzhen,
    Nanjing,
    Qingdao,
    Dalian,
    Tianjin,
    Guangzhou,
    Shijiazhuang,
    Chongqing,
    Kabul,
    Baghdad,
    Tehran,
    Riyadh,
    Taipei,
    Minsk,
    Roma,
    Malta,
    Athens,
    Prague,
    Warsaw,
    Vienna,
    Madrid,
    Stockholm,
    Oslo,
    Helsinki,
    Copenhagen,
    Amsterdam,
    Brussels,
    Hongkong,
    Singapore,
    KualaLumpur,
    Manila,
    Kiev,
    Phoenix,
    Nome,
    Seattle,
    Tallinn,
    Anchorage,
    Denver,
    PanamaCity,
    Dhaka,
    Urumqi,
    LosAngeles,
    NewYork,
    Chicago,
    London,
    Paris,
    Berlin,
    Moscow,
    Tokyo,
    Sydney,
    Shanghai,
    Beijing,
    Istanbul,
    Dubai,
    Mumbai,
    SaoPaulo,
    Suzhou
];

export const Cities = Array.from(new Set(CityArray)).sort((a, b) => a.cityName.localeCompare(b.cityName));
