import React, { useContext } from 'react';
import * as Styles from './styles';
import { AppContext } from './context';
import { TimeRulerProps, HourInAMPMProps } from './interface';
import { useMonthDayBasedOnOffsetHour } from './hooks/useDateTime';

const HourInAMPM: React.FC<HourInAMPMProps> = (props: HourInAMPMProps) => {
  return (
    <div>
      <div>{props.hour % 12}</div>
      <div style={Styles.ampmStyle}>{props.hour > 11 ? 'PM' : 'AM'}</div>
    </div>
  );
};

const DateUnit = (props: {month: string, day: string}) => {
  return (
    <div>
      <div style={{ fontSize: 14 }}>{props.month}</div>
      <div style={{ fontSize: 10 }}>{props.day}</div>
    </div>
  );
}

export const TimeRuler: React.FC<TimeRulerProps> = (props: TimeRulerProps) => {
  const { month, day } = useMonthDayBasedOnOffsetHour('en-US', props.timezone, props.hourList.findIndex(hour => hour === 0) || 0);
  const { hourMode } = useContext(AppContext);
  const listItems = props.hourList.map((hour: number) => {
    if (hour === 0) {
      return (
        <li style={{ ...Styles.getLiStyle(hour), paddingTop: 0}}>
          <DateUnit month={month} day={day} />
        </li>
      );
    }
  
    if (hourMode === 'AMPM') {
      return (
        <li style={{ ...Styles.getLiStyle(hour), paddingTop: 0}}>
          <HourInAMPM hour={hour} />
        </li>
      );
    }

    return (
      <li style={Styles.getLiStyle(hour)}>
        {hour}
      </li>
    );
  });


  return (
    <ul style={Styles.ulStyle} >
      {listItems}
    </ul>
  );
};
