import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../i18n/i18n';
export const FAQHead = (props: {language: string}) => {
    const { t, i18n } = useTranslation();

    return  (
        <Helmet htmlAttributes={{ lang: props.language }}>
            <meta charSet="utf-8" />
            <title>{t('headFAQ.title')}</title>
            <meta name="description" content={t('headFAQ.description')} />
            <meta name="keywords" content={t('headFAQ.keywords')} />
        </Helmet>
        );
}