import { MouseEvent, useState } from 'react';
import * as React from 'react';
import { SelectableAreaProps } from './interface';
import { AppContext } from './context';
import * as Styles from './styles';
import { getCurrentHour } from './hooks/useDateTime';


export const SelectableArea: React.FC<SelectableAreaProps> = (
  props: SelectableAreaProps,
) => {
  const [left, setLeft] = useState<number>(0);
  const [right, setRight] = useState<number>(props.selectableUnitWidth);
  const [mouseOffset, setMouseOffset] = useState<number>(0);
  const [anchor, setAnchor] = useState<number>(props.selectableUnitWidth / 2);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const {hasSelectedRange, setHasSelectedRange} = React.useContext(AppContext);
  const {setLeftIndex, setRightIndex} = React.useContext(AppContext);
  const {timeZoneRows} = React.useContext(AppContext);

  const firstRowTZID = timeZoneRows[0].city ? timeZoneRows[0].city.tzID : timeZoneRows[0].timezone!.sampleTZID;
  const hour = getCurrentHour('en-US', firstRowTZID);

  const showSelectBox = isMouseDown || hasSelectedRange;

  const onClick = (e: any) => {
    e.stopPropagation();
    if (!document.getElementById('control-layer')?.contains(e.target as Node)) {
      setHasSelectedRange?.(false);
      setMouseOffset(Number(hour)*2*props.selectableUnitWidth);
    }
  };

  React.useEffect(() => {
    setMouseOffset(Number(hour)*2*props.selectableUnitWidth);
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [hour]);

  const onMouseDown = (e: MouseEvent) => {
    e.stopPropagation();
    setIsMouseDown(true);
    setHasSelectedRange?.(false);

    const newLeft = Math.floor(e.nativeEvent.offsetX / props.selectableUnitWidth) * props.selectableUnitWidth;
    setAnchor(newLeft + props.selectableUnitWidth / 2);
    setLeft(newLeft);
    setLeftIndex(Math.floor(newLeft/props.selectableUnitWidth));

    const newRight = newLeft + props.selectableUnitWidth;
    setRight(newRight);
    setRightIndex(Math.floor(newRight/props.selectableUnitWidth));
  };

  const onMouseUp = (e: MouseEvent) => {
    e.stopPropagation();
    setHasSelectedRange?.(!hasSelectedRange);
    setIsMouseDown(false);
  };

  const onMouseMove = (e: MouseEvent) => {
    setMouseOffset(Math.floor(e.nativeEvent.offsetX / props.selectableUnitWidth) * props.selectableUnitWidth);
    if (isMouseDown) {
      const offsetX = e.nativeEvent.offsetX;
      if (offsetX > anchor) {
        const newRight = Math.ceil(offsetX / props.selectableUnitWidth) * props.selectableUnitWidth;
        setRight(newRight);
        setRightIndex(Math.floor(newRight/props.selectableUnitWidth));
      } else if (offsetX < anchor) {
        const newLeft = Math.floor(offsetX / props.selectableUnitWidth) * props.selectableUnitWidth;
        setLeft(newLeft);
        setLeftIndex(Math.floor(newLeft/props.selectableUnitWidth));
      }
    }
  };

  return (
    <div style={{ position: 'absolute', top: 0, left: Styles.SelectAreaLeft, width: props.width, height: props.height }} id="select-area">
      <div style={{ width: props.width, height: props.height, display: 'flex' }}>
        <div id="left-band" style={{ height: '100%', width: left }} />
        <div
          id="selected-band"
          style={{
            height: '100%',
            width: right - left,
            border: '2px solid green',
            borderRadius: 4,
          }}
          hidden={!showSelectBox}
        />
        <div
          id="right-band"
          style={{ height: '100%', width: props.width - right }}
        />
      </div>
      <div
          id="sliding-box"
          style={{
            height: '100%',
            width: props.selectableUnitWidth * 2,
            border: '2px dotted gray',
            borderRadius: 4,
            position: 'absolute',
            top: 0,
            left: mouseOffset,
            transition: 'left 0.1s ease-out'
          }}
          hidden={showSelectBox}
      />
      <div
        id='control-layer'
        style={{
          width: props.width,
          height: props.height,
          zIndex: 100,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
      </div>
    </div>
  );
};
