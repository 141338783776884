import * as React from 'react';
import { Tooltip } from 'antd';
import { CityTimezoneInfoProps, PureTimezoneProps, SelectedRangeProps, TimeAndDateInfoProps, TimezoneItem } from './interface';
import { CityInfoWidth, HourHeight } from './styles';
import { useCurrentDateTime } from './hooks/useDateTime';

const offsetToString = (offset: number) => {
  return offset >= 0 ? `UTC+${offset}` : `UTC${offset}`;
}

export const CityTimezone: React.FC<CityTimezoneInfoProps> = (
  props: CityTimezoneInfoProps,
) => {

  return (
    <div
      style={{
        display: 'flex',
        height: HourHeight,
        width: CityInfoWidth,
      }}
    >
      <div id="cityInfo">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <span style={{ fontSize: 15, fontWeight: 'bold', color: '#292929', overflow: 'hidden', maxWidth: CityInfoWidth - 30 }}>
            {props.cityName}
          </span>
          <div style={{ marginLeft: 4}}>
            <Tooltip placement='top' title={offsetToString(props.offset)}>
              <div style={{ fontSize: 11, color: '#888', backgroundColor: '#f2f2f2', borderRadius: 4, paddingLeft: 2, paddingRight: 2, cursor: 'default'}}>{props.tzAbbr}</div>
            </Tooltip>
          </div>
        </div>
        <div style={{ fontSize: 11, color: '#888' }}>{props.countryName}</div>
      </div>
    </div>
  );
};

export const PureTimezone: React.FC<PureTimezoneProps> = (
  props: PureTimezoneProps,
) => {

  return (
    <div
      style={{
        display: 'flex',
        height: HourHeight,
        width: CityInfoWidth,
      }}
    >
      <div id="tzInfo">
        <div>
            <b>{props.tzAbbrev}</b>
        </div>
        <div style={{ fontSize: 11, color: '#888' }}>{props.displayTime}</div>
      </div>
    </div>
  );
};

const TimeAndDateInfo: React.FC<TimeAndDateInfoProps> = (props: TimeAndDateInfoProps) => {
  return (
    <div id="time-info" style={{ marginLeft: 'auto', height: HourHeight }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: 'bold',
          color: '#292929',
          textAlign: 'right',
        }}
      >
        {props.time}
      </div>
      <div
        style={{
          fontSize: 11,
          color: '#888',
          marginLeft: 'auto',
          textAlign: 'right',
        }}
      >
        {props.date}
      </div>
  </div>
  );
}

export const CurrentTimeAndDate: React.FC<{ timezone: string }> = (props: { timezone: string }) => {
  const { weekday, time, date } = useCurrentDateTime('en-US', props.timezone);
  return <TimeAndDateInfo time={time} date={date} weekday={weekday} />;
}

export const SelectedDateRange: React.FC<SelectedRangeProps> = (props: SelectedRangeProps) => {
  return (
    <div style={{ display: 'flex', height: HourHeight }}>
      <TimeAndDateInfo {...props.left} />
      <div style={{ width: 20, height: HourHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ fontSize: 15, fontWeight: 'bold', color: '#292929' }}>~</div>
      </div>
      <TimeAndDateInfo {...props.right} />
    </div>
  );
}