import { TimezoneItem } from "../timezone-table/interface";

export const GreenwichMeanTime: TimezoneItem = {
    abbrev: "GMT",
    displayName: 'Greenwich Mean Time',
    UTCOffset: {STD: {hour: 0, minute: 0}, DST: {hour: 0, minute: 0}},
    sampleTZID: 'Europe/London'
}

export const CoordinatedUniversalTime: TimezoneItem = {
    abbrev: "UTC",
    displayName: 'Coordinated Universal Time',
    UTCOffset: {STD: {hour: 0, minute: 0}, DST: {hour: 0, minute: 0}},
    sampleTZID: 'Europe/London'
}

export const IndiaStandardTime: TimezoneItem = {
    abbrev: "IST",
    displayName: 'India Standard Time',
    UTCOffset: {STD: {hour: 5, minute: 30}, DST: {hour: 5, minute: 30}},
    sampleTZID: 'Asia/Kolkata'
}

export const CentralTime: TimezoneItem = {
    abbrev: "CT",
    displayName: 'Central Time',
    UTCOffset: {STD: {hour: -6, minute: 0}, DST: {hour: -5, minute: 0}},
    sampleTZID: 'America/Chicago'
}

export const CentralStandardTime: TimezoneItem = {
    abbrev: "CST",
    displayName: 'Central Standard Time',
    UTCOffset: {STD: {hour: -6, minute: 0}, DST: {hour: -6, minute: 0}},
    sampleTZID: 'America/Chicago'
}

export const CentralDaylightTime: TimezoneItem = {
    abbrev: "CDT",
    displayName: 'Central Daylight Time',
    UTCOffset: {STD: {hour: -5, minute: 0}, DST: {hour: -5, minute: 0}},
    sampleTZID: 'America/Chicago'
}

export const ChinaStandardTime: TimezoneItem = {
    abbrev: "CST",
    displayName: 'China Standard Time',
    UTCOffset: {STD: {hour: 8, minute: 0}, DST: {hour: 8, minute: 0}},
    sampleTZID: 'Asia/Shanghai'
}

export const BritishSummerTime: TimezoneItem = {
    abbrev: "BST",
    displayName: 'British Summer Time',
    UTCOffset: {STD: {hour: 1, minute: 0}, DST: {hour: 1, minute: 0}},
    sampleTZID: 'Europe/London'
}

export const AustralianEasternStandardTime: TimezoneItem = {
    abbrev: "AEST",
    displayName: 'Australian Eastern Standard Time',
    UTCOffset: {STD: {hour: 10, minute: 0}, DST: {hour: 10, minute: 0}},
    sampleTZID: 'Australia/Sydney'
}

export const EasternTime: TimezoneItem = {
    abbrev: "ET",
    displayName: 'Eastern Time',
    UTCOffset: {STD: {hour: -5, minute: 0}, DST: {hour: -4, minute: 0}},
    sampleTZID: 'America/New_York'
}

export const EasternStandardTime: TimezoneItem = {
    abbrev: "EST",
    displayName: 'Eastern Standard Time',
    UTCOffset: {STD: {hour: -5, minute: 0}, DST: {hour: -5, minute: 0}},
    sampleTZID: 'America/New_York'
}

export const EasternDaylightTime: TimezoneItem = {
    abbrev: "EDT",
    displayName: 'Eastern Daylight Time',
    UTCOffset: {STD: {hour: -4, minute: 0}, DST: {hour: -4, minute: 0}},
    sampleTZID: 'America/New_York'
}

export const PacificTime: TimezoneItem = {
    abbrev: "PT",
    displayName: 'Pacific Time',
    UTCOffset: {STD: {hour: -8, minute: 0}, DST: {hour: -7, minute: 0}},
    sampleTZID: 'America/Los_Angeles'
}

export const PacificStandardTime: TimezoneItem = {
    abbrev: "PST",
    displayName: 'Pacific Standard Time',
    UTCOffset: {STD: {hour: -8, minute: 0}, DST: {hour: -8, minute: 0}},
    sampleTZID: 'America/Los_Angeles'
}

export const PacificDaylightTime: TimezoneItem = {
    abbrev: "PDT",
    displayName: 'Pacific Daylight Time',
    UTCOffset: {STD: {hour: -7, minute: 0}, DST: {hour: -7, minute: 0}},
    sampleTZID: 'America/Los_Angeles'
}

export const CentralEuropeanTime: TimezoneItem = {
    abbrev: "CET",
    displayName: 'Central European Time',
    UTCOffset: {STD: {hour: 1, minute: 0}, DST: {hour: 2, minute: 0}},
    sampleTZID: 'Europe/Paris'
}

export const CentralEuropeanSummerTime: TimezoneItem = {
    abbrev: "CEST",
    displayName: 'Central European Summer Time',
    UTCOffset: {STD: {hour: 2, minute: 0}, DST: {hour: 2, minute: 0}},
    sampleTZID: 'Europe/Paris'
}

export const EasternEuropeanTime: TimezoneItem = {
    abbrev: "EET",
    displayName: 'Eastern European Time',
    UTCOffset: {STD: {hour: 2, minute: 0}, DST: {hour: 3, minute: 0}},
    sampleTZID: 'Europe/Bucharest'
}

export const EasternEuropeanSummerTime: TimezoneItem = {
    abbrev: "EEST",
    displayName: 'Eastern European Summer Time',
    UTCOffset: {STD: {hour: 3, minute: 0}, DST: {hour: 3, minute: 0}},
    sampleTZID: 'Europe/Bucharest'
}

export const MountainTime: TimezoneItem = {
    abbrev: "MT",
    displayName: 'Mountain Time',
    UTCOffset: {STD: {hour: -7, minute: 0}, DST: {hour: -6, minute: 0}},
    sampleTZID: 'America/Denver'
}

export const MountainStandardTime: TimezoneItem = {
    abbrev: "MST",
    displayName: 'Mountain Standard Time',
    UTCOffset: {STD: {hour: -7, minute: 0}, DST: {hour: -7, minute: 0}},
    sampleTZID: 'America/Denver'
}

export const MountainDaylightTime: TimezoneItem = {
    abbrev: "MDT",
    displayName: 'Mountain Daylight Time',
    UTCOffset: {STD: {hour: -6, minute: 0}, DST: {hour: -6, minute: 0}},
    sampleTZID: 'America/Denver'
}

export const JapanStandardTime: TimezoneItem = {
    abbrev: "JST",
    displayName: 'Japan Standard Time',
    UTCOffset: {STD: {hour: 9, minute: 0}, DST: {hour: 9, minute: 0}},
    sampleTZID: 'Asia/Tokyo'
}

export const KoreaStandardTime: TimezoneItem = {
    abbrev: "KST",
    displayName: 'Korea Standard Time',
    UTCOffset: {STD: {hour: 9, minute: 0}, DST: {hour: 9, minute: 0}},
    sampleTZID: 'Asia/Seoul'
}

export const MoscowStandardTime: TimezoneItem = {
    abbrev: "MSK",
    displayName: 'Moscow Standard Time',
    UTCOffset: {STD: {hour: 3, minute: 0}, DST: {hour: 3, minute: 0}},
    sampleTZID: 'Europe/Moscow'
}

export const PhilippineTime: TimezoneItem = {
    abbrev: "PHT",
    displayName: 'Philippine Time',
    UTCOffset: {STD: {hour: 8, minute: 0}, DST: {hour: 8, minute: 0}},
    sampleTZID: 'Asia/Manila'
}

export const SingaporeTime: TimezoneItem = {
    abbrev: "SGT",
    displayName: 'Singapore Time',
    UTCOffset: {STD: {hour: 8, minute: 0}, DST: {hour: 8, minute: 0}},
    sampleTZID: 'Asia/Singapore'
}

export const TurkeyTime: TimezoneItem = {
    abbrev: "TRT",
    displayName: 'Turkey Time',
    UTCOffset: {STD: {hour: 3, minute: 0}, DST: {hour: 3, minute: 0}},
    sampleTZID: 'Europe/Istanbul'
}

export const UzbekistanTime: TimezoneItem = {
    abbrev: "UZT",
    displayName: 'Uzbekistan Time',
    UTCOffset: {STD: {hour: 5, minute: 0}, DST: {hour: 5, minute: 0}},
    sampleTZID: 'Asia/Tashkent'
}

export const WesternIndonesianTime: TimezoneItem = {
    abbrev: "WIB",
    displayName: 'Western Indonesian Time',
    UTCOffset: {STD: {hour: 7, minute: 0}, DST: {hour: 7, minute: 0}},
    sampleTZID: 'Asia/Jakarta'
}

export const EasternIndonesianTime: TimezoneItem = {
    abbrev: "WIT",
    displayName: 'Eastern Indonesian Time',
    UTCOffset: {STD: {hour: 9, minute: 0}, DST: {hour: 9, minute: 0}},
    sampleTZID: 'Asia/Jayapura'
}

export const CentralIndonesianTime: TimezoneItem = {
    abbrev: "WITA",
    displayName: 'Central Indonesian Time',
    UTCOffset: {STD: {hour: 8, minute: 0}, DST: {hour: 8, minute: 0}},
    sampleTZID: 'Asia/Makassar'
}

export const TimeZoneList: TimezoneItem[] = [
    GreenwichMeanTime,
    CoordinatedUniversalTime,
    IndiaStandardTime,
    CentralTime,
    CentralStandardTime,
    CentralDaylightTime,
    ChinaStandardTime,
    BritishSummerTime,
    AustralianEasternStandardTime,
    EasternTime,
    EasternStandardTime,
    EasternDaylightTime,
    PacificTime,
    PacificStandardTime,
    PacificDaylightTime,
    CentralEuropeanTime,
    CentralEuropeanSummerTime,
    EasternEuropeanTime,
    EasternEuropeanSummerTime,
    MountainTime,
    MountainStandardTime,
    MountainDaylightTime,
    JapanStandardTime,
    KoreaStandardTime,
    MoscowStandardTime,
    PhilippineTime,
    SingaporeTime,
    TurkeyTime,
    UzbekistanTime,
    WesternIndonesianTime,
    EasternIndonesianTime,
    CentralIndonesianTime,
]