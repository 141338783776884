import { Cities } from "../../consts/city-list";
import { TimeZoneList } from "../../consts/tz-list";
import { CityTZProps, TimezoneItem } from "../interface";

export const searchCity = (query: string): CityTZProps[] => {
    if (!query) {
        return Cities;
    }
    return Cities.filter(city => 
        city.cityName.toLowerCase().includes(query.toLowerCase()) || 
        city.tzID.toLowerCase().includes(query.toLowerCase()) || 
        city.tzAbbr.STD.toLowerCase() === query.toLowerCase() || 
        city.tzAbbr.DST.toLowerCase() === query.toLowerCase());
};

export const searchTZ = (query: string): TimezoneItem[] => {
    if (!query) {
        return [];
    }

    const queryLowerCase = query.toLowerCase();
    return TimeZoneList.filter(tz => 
        tz.abbrev.toLowerCase().includes(queryLowerCase) || 
        tz.displayName.toLowerCase().includes(queryLowerCase));
}