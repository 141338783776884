export const codeMap: {[i18nCode: string]: string} = {
    "en": "English",
    "zh": "中文",
    "de": "Deutsch",
    "es": "Español",
    "fr": "Français",
    "ru": "Русский", 
    "ja": "日本語",
    "ko": "한국어",
    // "pl": "Polski", // 波兰语
    // "pt-BR": "Português (do Brasil)" // 巴西葡萄牙语
};

export const getLocaleKeys = (): string[] => {
    return Object.keys(codeMap);
}
