/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import * as React from 'react';
import { AppContext, getCityOrTimezone } from './context';
import { Space, Select } from 'antd';
import { Cities } from '../consts/city-list';
import { searchCity, searchTZ } from './hooks/useSearch';
import { CityTZProps, TimezoneItem } from './interface';
import { useTranslation } from 'react-i18next';

export const SearchBar: React.FC = () => {
    const {addRow} = React.useContext(AppContext);
    const [cities, setCities] = React.useState(Cities);
    const [timezones, setTimezones] = React.useState<TimezoneItem[]>([]);
    const { t, i18n } = useTranslation();

  
    const onChange = (value: string) => {
      console.log("on change");
      const cityOrTimezone = getCityOrTimezone(value);
      if (cityOrTimezone){
        addRow?.(cityOrTimezone);
        setTimezones([]);
        setCities(Cities);
      }
    };
  
    const onSearch = (value: string) => {
      const filterCities = searchCity(value) || [];
      setCities(filterCities);

      const filterTZ = searchTZ(value) || [];
      setTimezones(filterTZ);
      console.log('onsearch', filterTZ, filterCities);
    };
  
    return (
      <Space direction="vertical">
        <Select
          showSearch
          showArrow
          value={null}
          allowClear
          filterOption={false}
          autoClearSearchValue
          style={{ width: 300 }}
          placeholder={t('addCity')}
          onChange={onChange}
          // onSelect={}
          onSearch={onSearch}
        >
          {
            timezones.map(tz => 
              <Select.Option 
                key={tz.displayName}
                value={tz.displayName}
              >
                <div>
                  <span>{tz.displayName}</span>
                  &nbsp;&nbsp;
                  <b>{tz.abbrev}</b>
                </div>
              </Select.Option>)
          }
          {
            cities.map(city => 
              <Select.Option 
                  key={city.cityName}
                  value={city.cityName}
              >
                {DropListCityOption(city)}
              </Select.Option>)
          }
        </Select>
      </Space>
    );
  }

const TAG = (props: { tz: string }) => {
  return (
  <div style={{ fontSize: 11, color: '#888', backgroundColor: '#f2f2f2', marginLeft: 8, borderRadius: 4, paddingLeft: 2, paddingRight: 2 }}>
    {props.tz}
  </div>);
}

function DropListCityOption(city: CityTZProps) {

  return <div style={{ display: 'flex' }}>
    <b>{city.cityName}</b>
    <span style={{ color: 'gray', marginLeft: 4 }}>
      <small>
        {city.country}
      </small>
    </span>
    <TAG tz={city.tzAbbr.STD} />
    {city.tzAbbr.DST !== city.tzAbbr.STD &&
      <TAG tz={city.tzAbbr.DST} />}
  </div>;
}
